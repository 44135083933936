import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/why.scss"
import Section from "../components/section"
import SectionSubtitle from "../components/sectionSubtitle"
import Text from "../components/text"
import Footer from "../components/footer"
import Contact from "../components/contact"

const Depression = () => (
  <Layout>
    <SEO
      lang="it"
      title="Cosa e' la depressione? | Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
      description="Descrizione della depressione di Stefano Maffina, psicologo clinico"
    />
    <div className="navbar paddings-lr">
      <div style={{ width: "1050px", margin: "0 auto", paddingLeft: "16px" }}>
        <Link to="/">
          <span>Indietro</span>
        </Link>
      </div>
    </div>
    <Section isAbout color="#f8f8f8">
      <div style={{ paddingTop: "64px" }}>
        <div className="section-subtitle">
          <h2
            data-aos="custom-fade"
            data-aos-duration="1000"
            style={{ color: "#121212" }}
          >
            <span style={{ fontWeight: 700, textDecoration: "italic" }}>
              Depressione
            </span>{" "}
          </h2>
        </div>
        <div className="paddings-lr why-content">
          <p>
            è caratterizzata da un costante stato di tristezza e da una generale
            perdita di interesse per quasi tutte le attività, mancanza di
            energie, grande affaticamento e difficoltà a svolgere anche piccoli
            compiti quotidiani.
          </p>
          <p>
            La depressione può portare a cambiamenti nell’alimentazione, ad
            alterazioni del sonno, a manifestazioni somatiche e perdita del
            desiderio sessuale.
          </p>
          <p>
            Gli individui depressi presentano spesso sentimenti di
            autosvalutazione e di colpa eccessivi, pensieri negativi riguardo la
            propria inutilità, incapacità e non amabilità, nessuna speranza per
            il futuro e pieni di rimpianti verso il passato.
          </p>
          <p>
            Può capitare che pensino che la morte possa essere l'unica soluzione
            al problema.
          </p>
        </div>
      </div>
    </Section>
    <Contact />
    <Footer />
  </Layout>
)

export default Depression
